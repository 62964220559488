import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axios } from 'utils/api';

const initialState = {
  entities: [],
  selected: null,
  loading: false,
};

export const fetchClassroom = createAsyncThunk('classroom/fetch', async () => {
  const { data } = await axios.get('/classroom_teachers?per_page=100');
  return data.data;
});

const classroomSlice = createSlice({
  name: 'classroom',
  initialState,
  reducers: {
    setSelected(state, action) {
      const selected = state.entities.find((item) => {
        return item.classroom_subject_id === action.payload;
      });
      if (selected) state.selected = selected;
    },
  },
  extraReducers: {
    [fetchClassroom.pending]: (state) => {
      state.loading = true;
    },
    [fetchClassroom.rejected]: (_, action) => {
      console.log(action);
    },
    [fetchClassroom.fulfilled]: (state, action) => {
      state.loading = false;
      state.entities = action.payload;
      state.entities = state.entities.map((item) => {
        item.turma = {
          name: item.classroom_subject.classroom.name,
          start: item.start_date_format,
          end: item.end_date_format,
        };
        return item;
      });
    },
  },
});

export const { setSelected } = classroomSlice.actions;

export default classroomSlice.reducer;
