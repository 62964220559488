import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  upload: {
    tags: [],
    providerMediaId: '',
    typeMedia: '1',
    name: '',
    description: '',
    url: '',
  },
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    updateContent(state, action) {
      state.upload = { ...state.upload, ...action.payload };
    },
    reset: () => initialState,
  },
});

export const { updateContent, reset } = contentSlice.actions;

export default contentSlice.reducer;
