import React from 'react';
import { Chip } from '@material-ui/core';
import { Home, Settings, Folder, BookOpen } from 'react-feather';
import { School, People, AssignmentOutlined, Launch } from '@material-ui/icons';
import { IoFlaskSharp as IoFlask } from 'react-icons/io5';

export const adminRoutes = [
  {
    subheader: 'Geral',
    items: [
      {
        title: 'Home',
        icon: Home,
        href: '/home',
      },
      {
        title: 'MinhaBiblioteca',
        icon: BookOpen,
        href: '/externo-minha-biblioteca',
        info: () => <Chip color="secondary" size="small" label="Novo" />,
      },
      {
        title: 'Links Úteis',
        icon: Launch,
        href: '/externos',
        items: [
          {
            title: 'Painel dos Professores',
            href: '/externo-painel-professores',
          },
          {
            title: 'Grupo dos Professores',
            href: '/externo-grupo-professores',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Educacional',
    items: [
      {
        title: 'Turmas',
        icon: People,
        href: '/educacional/turmas',
      },
      {
        title: 'Disciplinas',
        icon: School,
        href: '/educacional/disciplinas',
      },
      {
        title: 'Biblioteca de Conteúdo',
        icon: Folder,
        href: '/educacional/conteudo',
      },
      {
        title: 'Atividades',
        icon: AssignmentOutlined,
        href: '/educacional/atividades',
      },
      {
        title: 'Laboratorios',
        icon: IoFlask,
        href: '/educacional/laboratorios',
        info: () => <Chip color="secondary" size="small" label="Novo" />,
      },
    ],
  },
  {
    subheader: 'Configurações',
    items: [
      {
        title: 'Preferências',
        icon: Settings,
        href: '/configuracoes',
      },
    ],
  },
];

export const teacherRoutes = [
  {
    subheader: 'Geral',
    items: [
      {
        title: 'Home',
        icon: Home,
        href: '/home',
      },
      {
        title: 'MinhaBiblioteca',
        icon: BookOpen,
        href: '/externo-minha-biblioteca',
        info: () => <Chip color="secondary" size="small" label="Novo" />,
      },
      {
        title: 'Links Úteis',
        icon: Launch,
        href: '/externos',
        items: [
          {
            title: 'Painel dos Professores',
            href: '/externo-painel-professores',
          },
          {
            title: 'Grupo dos Professores',
            href: '/externo-grupo-professores',
          },
        ],
      },
    ],
  },
  {
    subheader: 'Educacional',
    items: [
      {
        title: 'Turmas',
        icon: People,
        href: '/educacional/turmas',
      },
      {
        title: 'Biblioteca de Conteúdo',
        icon: Folder,
        href: '/educacional/conteudo',
      },
      {
        title: 'Atividades',
        icon: AssignmentOutlined,
        href: '/educacional/atividades',
      },
      {
        title: 'Laboratorios',
        icon: IoFlask,
        href: '/educacional/laboratorios',
        info: () => <Chip color="secondary" size="small" label="Novo" />,
      },
    ],
  },
  {
    subheader: 'Configurações',
    items: [
      {
        title: 'Preferências',
        icon: Settings,
        href: '/configuracoes',
      },
    ],
  },
];
