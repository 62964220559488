import jwtDecode from 'jwt-decode';
import Axios from 'axios';

const baseURL =
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://api-nav.gsaeducacional.com.br/api'
    : process.env.REACT_APP_ENV === 'test'
    ? 'http://testes-api-nav.gsaeducacional.com.br/api'
    : 'https://homolog-api-nav.gsaeducacional.com.br/api';

export const axios = Axios.create({
  baseURL,
});

export const mockAxios = Axios.create();

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.request.use(
      (config) => {
        const token = this.getAccessToken();
        let originalRequest = config;
        if (
          token &&
          this.isExpiredToken(token) &&
          originalRequest.url !== '/refresh'
        ) {
          return this.refreshToken()
            .then((newToken) => {
              originalRequest.headers.Authorization = 'Bearer ' + newToken;
              this.setSession(newToken);
              return Promise.resolve(originalRequest);
            })
            .catch((err) => {
              this.setSession(null);
              Promise.reject(err);
            });
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          originalRequest.url === '/refresh'
        ) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }

        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry &&
          originalRequest.url !== '/login'
        ) {
          originalRequest._retry = true;
          return this.refreshToken()
            .then((newToken) => {
              this.setSession(newToken);
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      const accessToken = this.getAccessToken();

      if (!accessToken) {
        reject('Sem token de autenticação');
      } else if (this.isValidToken(accessToken)) {
        this.setSession(accessToken);
        resolve('Autenticado');
      } else {
        this.setSession(accessToken);
        this.refreshToken()
          .then((newToken) => {
            this.setSession(newToken);
            resolve('Autenticado');
          })
          .catch((err) => {
            this.setSession(null);
            reject(err);
          });
      }
    });
  }

  loginWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/login', { email, password })
        .then((response) => {
          if (this.isValidToken(response.data.token)) {
            this.setSession(response.data.token);
            resolve(response.data.token);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginWithUserCodeAndPassword = (coduser, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/login', { coduser, password })
        .then((response) => {
          if (this.isValidToken(response.data.token)) {
            this.setSession(response.data.token);
            resolve(response.data.token);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  refreshToken = () =>
    new Promise((resolve, reject) => {
      axios
        .post('/refresh')
        .then((response) => {
          if (this.isValidToken(response.data.token)) {
            resolve(response.data.token);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log(error.response);
          reject(error);
        });
    });

  loginInWithToken = () => {
    const token = this.getAccessToken();
    const decoded = jwtDecode(token);
    return decoded;
  };

  logout = () => {
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.removeItem('accessToken');
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);

    if (!(decoded.profile === 'teacher' || decoded.profile === 'admin')) {
      return false;
    }
    return !this.isExpiredToken(accessToken);
  };

  isExpiredToken = (accessToken) => {
    const now = Date.now() / 1000;
    const decoded = jwtDecode(accessToken);
    return now > decoded.exp;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
