import mock from './config';

mock.onGet('/api/educational/subjects').reply(200, {
  subjects: [
    {
      id: 1,
      name: 'Gestão do Futuro',
      course: 'Administração',
      instructor: 'Will Amaral',
      classroom: 'Turma 1',
      classPlan: {
        link: '/educacional/disciplinas/1',
      },
    },
    {
      id: 2,
      name: 'Lógica de Programação',
      course: 'Ciências da Computação',
      instructor: 'Will Amaral',
      classroom: 'Turma 1',
      classPlan: {
        link: '/educacional/disciplinas/1',
      },
    },
    {
      id: 3,
      name: 'Cálculo I',
      course: 'Engenharia',
      instructor: 'Will Amaral',
      classroom: 'Turma 2',
      classPlan: {
        link: '/educacional/disciplinas/1',
      },
    },
    {
      id: 4,
      name: 'Gestão do Futuro',
      course: 'Administração',
      instructor: 'Will Amaral',
      classroom: 'Turma 2',
      classPlan: {
        link: '/educacional/disciplinas/1',
      },
    },
    {
      id: 5,
      name: 'Lógica de Programação',
      course: 'Ciências da Computação',
      instructor: 'Will Amaral',
      classroom: 'Turma 1',
      classPlan: {
        link: '/educacional/disciplinas/1',
      },
    },
    {
      id: 6,
      name: 'Cálculo I',
      course: 'Engenharia',
      instructor: 'Will Amaral',
      classroom: 'Turma 3',
      classPlan: {
        link: '/educacional/disciplinas/2',
      },
    },
  ],
});

mock.onGet('/api/educational/lesson/1').reply(200, {
  id: '1',
  title: 'Gestão do Futuro',
  course: 'Administração',
  instructor: 'Will Amaral',
  schedule: 'Terças e Quintas | Noturno ',
  description:
    'Aprenda e pratique UX (Experiência do Usuário) e Design Thinking com centenas de exemplos práticos para se destacar no mercado de Product Design (design de produtos)! O curso "UX Design e Design Thinking" é uma coletânea de diversas técnicas que abordam desde as ferramentas práticas de UX até os processos de trabalho que guiam equipes centradas no usuário para você se tornar um profissional completo e com capacidades para liderar equipes. Técnicas de UX que você aprenderá no curso Conheça mais de 50 técnicas que passam pela Estratégia, Geração de ideias, Planejamento, Desenho, Validação e Pesquisa, Métricas e Lançamento MVP. Métricas de UX que você aprenderá no curso Aprenda a utilizar métricas de sucesso para produtos e métricas de UX para avaliar, dirigir e monitorar o design dos seus produtos. Entre as métricas de produtos, conheça o HEART, uma forma de medir importantes aspectos do contato dos usuários com seus produtos: Happiness (felicidade), Engagement (engajamento), Adoption (adoção), Retention (retenção) e Task Success (sucesso das tarefas). Bônus do curso "Lean UX: Times ágeis com foco na Experiência do Usuário"',
});
