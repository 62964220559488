import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Dashboard from 'components/Layout/Dashboard';
import LoadingScreen from 'components/LoadingScreen';
import { AuthRoute, GuestRoute } from 'components/Auth';
import api, { axios } from 'utils/api';

function Routes() {
  const Error404 = lazy(() => import('components/Error404'));
  const Blank = lazy(() => import('pages/Blank'));
  const Settings = lazy(() => import('pages/Settings'));
  const Login = lazy(() => import('pages/Auth/Login'));
  const Home = lazy(() => import('pages/Home'));
  const DisciplinasList = lazy(() =>
    import('pages/Educacional/Disciplinas/List')
  );
  const DisciplinasDetail = lazy(() =>
    import('pages/Educacional/Disciplinas/Detail')
  );
  const DisciplinasEdit = lazy(() =>
    import('pages/Educacional/Disciplinas/Edit')
  );
  const TurmasList = lazy(() => import('pages/Educacional/Turmas/List'));
  const TurmasEdit = lazy(() => import('pages/Educacional/Turmas/Edit/Edit'));
  const StudentsList = lazy(() =>
    import('pages/Educacional/Turmas/StudentsList')
  );
  const Classplan = lazy(() => import('pages/Educacional/Turmas/Classplan'));
  const BibliotecaList = lazy(() =>
    import('pages/Educacional/Biblioteca/List')
  );
  const BibliotecaCreate = lazy(() =>
    import('pages/Educacional/Biblioteca/Create')
  );
  const AtividadesCreate = lazy(() =>
    import('pages/Educacional/Atividades/Create')
  );
  const AtividadeDetail = lazy(() =>
    import('pages/Educacional/Atividades/Detail')
  );
  const AtividadeEdit = lazy(() => import('pages/Educacional/Atividades/Edit'));
  const Atividades = lazy(() => import('pages/Educacional/Atividades/List'));
  const Laboratorios = lazy(() =>
    import('pages/Educacional/Laboratorios/Main')
  );
  const LabList = lazy(() => import('pages/Educacional/Laboratorios/List'));
  const ExternalLink = lazy(() => import('pages/ExternalLink'));

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path="/404" component={Error404} />
        <GuestRoute exact path="/login" component={Login} />
        <AuthRoute
          path="/"
          render={(props) => (
            <Dashboard {...props}>
              <Suspense fallback={<LoadingScreen />}>
                <Switch>
                  <Redirect exact from="/" to="/home" />
                  <Redirect
                    exact
                    from="/educacional"
                    to="/educacional/disciplinas"
                  />
                  <Route exact path="/home" component={Home} />
                  <Route
                    exact
                    path="/educacional/disciplinas"
                    component={DisciplinasList}
                  />
                  <Route
                    exact
                    path="/educacional/disciplinas/:id"
                    component={DisciplinasDetail}
                  />
                  <Route
                    exact
                    path="/educacional/disciplinas/:id/edit"
                    component={DisciplinasEdit}
                  />
                  <Route
                    exact
                    path="/educacional/turmas"
                    component={TurmasList}
                  />
                  <Route
                    exact
                    path="/educacional/turmas/:id/plano"
                    component={Classplan}
                  />
                  <Redirect
                    exact
                    path="/educacional/turmas/:id"
                    to="/educacional/turmas/:id/plano"
                  />
                  <Route
                    exact
                    path="/educacional/turmas/:id/lista-de-alunos"
                    component={StudentsList}
                  />
                  <Route
                    exact
                    path="/educacional/turmas/:classid/aula/:id"
                    component={TurmasEdit}
                  />
                  <Route
                    exact
                    path="/educacional/conteudo"
                    component={BibliotecaList}
                  />
                  <Route
                    exact
                    path="/educacional/conteudo/novo"
                    component={BibliotecaCreate}
                  />
                  <Route
                    exact
                    path="/educacional/atividades"
                    component={Atividades}
                  />
                  <Route
                    exact
                    path="/educacional/atividades/nova"
                    component={AtividadesCreate}
                  />
                  <Route
                    exact
                    path="/educacional/atividades/:id"
                    component={AtividadeDetail}
                  />
                  <Route
                    exact
                    path="/educacional/atividades/:id/edit"
                    component={AtividadeEdit}
                  />
                  <Route
                    exact
                    path="/educacional/laboratorios"
                    component={Laboratorios}
                  />
                  <Route
                    exact
                    path="/educacional/laboratorios/:id"
                    component={LabList}
                  />
                  <Route exact path="/configuracoes" component={Settings} />
                  <Route
                    exact
                    path="/externo-minha-biblioteca"
                    component={() => {
                      const token = api.getAccessToken();
                      const href = `${axios.defaults.baseURL}/my-libraries?token=${token}`;
                      window.open(href);
                      return (
                        <ExternalLink title="Minha biblioteca" href={href} />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/externo-painel-professores"
                    component={() => {
                      const href =
                        'https://sites.google.com/triventoeducacao.com.br/prof-trivento-2021-2/in%C3%ADcio';
                      window.open(href);
                      return (
                        <ExternalLink
                          title="Painel dos Professores"
                          href={href}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/externo-grupo-professores"
                    component={() => {
                      const href =
                        'https://chat.whatsapp.com/I7GbFhvtU310ZVGLqskA12';
                      window.open(href);
                      return (
                        <ExternalLink
                          title="Grupo dos Professores Trivento"
                          href={href}
                        />
                      );
                    }}
                  />
                  <Route component={Blank} />
                </Switch>
              </Suspense>
            </Dashboard>
          )}
        />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  );
}

export default Routes;
