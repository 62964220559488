import { configureStore } from '@reduxjs/toolkit';

import account from './accountSlice';
import notifications from './notificationsSlice';
import settings from './settingsSlice';
import classroom from './classroomSlice';
import content from './contentSlice';

const store = configureStore({
  reducer: {
    account,
    notifications,
    settings,
    classroom,
    content,
  },
});

export default store;
