import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import NavBar from '../NavBar';
import TopBar from '../TopBar';
import { useStyles } from './styles';
import { fetchClassroom } from 'store/classroomSlice';

function Dashboard(props) {
  const { children } = props;
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClassroom());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
