import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService, { axios } from 'utils/api';
import { capitalize } from 'utils/helper';

const initialState = {
  user: null,
  loading: false,
};

export const login = createAsyncThunk('account/login', async () => {
  const user = await authService.loginInWithToken();
  const name = user.name.split(' ');
  user.firstName = capitalize(name[0]);
  user.lastName = capitalize(name[name.length - 1]);
  user.bio = user.profile === 'teacher' ? 'Professor' : 'Administrador';
  return user;
});

export const refreshAccount = createAsyncThunk(
  'account/refresh',
  async (_, { dispatch }) => {
    const { data } = await axios.get('/user_profiles');
    if (data.data[0]) {
      dispatch(setUserData(data.data[0]));
    }
  }
);

export const refreshToken = createAsyncThunk('account/token', async () => {
  const user = await authService.refreshToken();
  const name = user.name.split(' ');
  user.firstName = capitalize(name[0]);
  user.lastName = capitalize(name[name.length - 1]);
  user.bio = user.profile === 'teacher' ? 'Professor' : 'Administrador';
  return user;
});

export const logout = createAsyncThunk('account/logout', async () => {
  await authService.logout();
});

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setUserData(state, action) {
      state.user = { ...state.user, ...action.payload, ...action.payload.user };
      state.user.photo = state.user.urlweb;
      const name = action.payload.user.name.split(' ');
      state.user.firstName = capitalize(name[0]);
      state.user.lastName = capitalize(name[name.length - 1]);
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [refreshToken.fulfilled]: (state, action) => {
      state.user = action.payload;
    },
    [refreshAccount.pending]: (state) => {
      state.loading = true;
    },
    [refreshAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [refreshAccount.rejected]: (state) => {
      state.loading = false;
    },
    [logout.fulfilled]: (state) => {
      state.user = null;
    },
  },
});

export const { setUserData } = accountSlice.actions;

export default accountSlice.reducer;
